import React from "react"


export default class Contact extends React.Component {
    render() {
        return(
            <div className="content">
                 <h3>Contact Me</h3>
                <div className="content-box">
            <h1 id="dash"> {">"} {">"} {">"}</h1>
            <p>I love hearing from people!</p>
            <p>ageeab@gmail.com</p>
            <p><a href="https://github.com/acornsquash" target="_blank" rel="noopener noreferrer">github.com/acornsquash</a></p>
            <h1 id="dash"> {">"} {">"} {">"}</h1>
            </div>
            </div>

        )
    }
}